import React from "react";
import styled from "styled-components"

const Icon = styled.svg `
  width: 35px;
  height: 32px;
  
  .cls-1{
    fill: #67923d;
  }
`;

const SupportIcon = () => {
    return (

<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.59331 32.58712">
  <title>SupportIcon</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <rect id="Rectangle_526" data-name="Rectangle 526" class="cls-1" x="2.41587" y="12.01285" width="1.05126" height="1.20929"/>
      <rect id="Rectangle_527" data-name="Rectangle 527" class="cls-1" x="5.24888" y="18.85285" width="1.05126" height="1.20861" transform="translate(-12.06697 9.78206) rotate(-45)"/>
      <rect id="Rectangle_528" data-name="Rectangle 528" class="cls-1" x="5.1702" y="5.2515" width="1.20861" height="1.05126" transform="translate(-2.39373 5.77556) rotate(-45.002)"/>
      <path id="Path_5400" data-name="Path 5400" class="cls-1" d="M31.83288,24.10845a9.50069,9.50069,0,0,0-7.4721-5.73242L21.0266,25.01186,17.69241,18.376a9.49526,9.49526,0,0,0-7.15535,5.065L10.05755,21.523a10.356,10.356,0,0,1-1.92686-.80236l-2.3555,1.41345-2.677-2.677,1.41344-2.3555a10.35078,10.35078,0,0,1-.80235-1.92618l-2.66-.66534V10.7249l2.66-.66534a10.242,10.242,0,0,1,.80235-1.9255L3.09821,5.7772l2.677-2.677L8.13137,4.51432a10.29928,10.29928,0,0,1,1.92618-.803l.66468-2.66h3.78521l.66534,2.66a10.27251,10.27251,0,0,1,1.9255.803l2.35686-1.41411L21.637,5.28276a6.13258,6.13258,0,0,0-5.1729,2.015C12.3269,4.12638,5.89929,7.38665,6.04374,12.61786c-.179,5.75074,7.43683,8.81024,11.2858,4.56451,6.87932,6.87457,14.43891-8.427,6.05933-11.45671l.06782-.11123L19.61926,1.777,17.05688,3.31386a10.35729,10.35729,0,0,0-1.00311-.41916L15.33011,0H9.90424L9.18057,2.8947a10.1895,10.1895,0,0,0-1.00311.41916L5.61509,1.777,1.777,5.61443,3.31452,8.1768A10.51062,10.51062,0,0,0,2.8947,9.17991L0,9.90358v5.42586l2.89539.723a10.189,10.189,0,0,0,.41915,1.00311L1.777,19.61793l3.83812,3.83812L8.17748,21.9185a10.433,10.433,0,0,0,1.00311.41915L9.869,25.09128a9.70347,9.70347,0,0,0,.82,7.49584h20.6786A9.68721,9.68721,0,0,0,31.83288,24.10845ZM12.61514,18.14C2.95506,17.58522,7.66405,2.95438,15.82862,8.13675c-1.63726,2.5644-.96513,5.84705.81389,8.25206a5.49375,5.49375,0,0,1-4.02737,1.74849ZM26.112,11.39226c-.74606,9.42338-10.75406,7.6186-10.084-.93665.9475-5.87824,10.19656-5.14574,10.08533.93394Zm4.60587,20.14828H11.334c-2.367-4.71984.59208-10.967,5.77448-11.98234l3.91816,7.79968,3.91816-7.79968c5.18169,1.01327,8.155,7.26726,5.77446,11.97963Z"/>
      <rect id="Rectangle_529" data-name="Rectangle 529" class="cls-1" x="25.23232" y="25.76064" width="4.20572" height="1.05126"/>
    </g>
  </g>
</Icon>
    )
}
export default SupportIcon
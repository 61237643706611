import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleRight } from "@fortawesome/pro-light-svg-icons"

import styles from "../components/CompleteCarePage/CompleteCarePage.module.css"

import CompleteCareHero from "../components/CompleteCarePage/CompleteCareHero"
import CompleteCareTopCards from "../components/CompleteCarePage/CompleteCareTopCards"
import HoverCardBlock from "../components/HoverCardBlock/HoverCardBlock"
import CompleteCareTabs from "../components/CompleteCarePage/CompleteCareTabs"
import CommunicationIsEverything from "../components/CompleteCarePage/CommunicationIsEverything"
import Assessment from "../components/Assessment/Assesment"
import FooterContact from "../components/FooterContact/FooterContact"
import PredictableCostTabs from "../components/CompleteCarePage/PredictableCostTabs"

const Overview = ({element}) => {

  // Query for HoverCardBlock image
  const data = useStaticQuery(graphql`
  query {
  underestimateTime: file(relativePath: { eq: "underestimate-time.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  }
  `)

return (
  <Layout>
    <SEO title="Complete Care Approach" />
    <div>
      <CompleteCareHero>
        <Container className="hero-container">
          <Row className="hero-flex-row">
            <Col style={{marginBottom: '100px'}}>
            <h1 className={styles.heroHeadline}>
              We're working hard to keep<br/>you home... Here's how it works
            </h1>
            </Col>
          </Row>
        </Container>
      </CompleteCareHero>
    </div>

    <Container style={{
      marginTop: "-90px"
    }}>
    <Row style={{
                  height: '100%'
                }}>
              <Col
                className="vertical-center p-4"
                sm={12}
              >
              <CompleteCareTopCards />
              </Col>
      </Row>
      </Container>

      <Container className="big-y-margin">
        <HoverCardBlock
          fluid={data.underestimateTime.childImageSharp.fluid}
          alt="Let our experts guide you"
          style={{alignSelf: 'flex-end', marginBottom: '-40px'}}
    >
      <div>
      <p className={styles.hoverCardText}>The number of seniors age 75-84 will rise from 15 million to 25 million by 2030. Most underestimate the time and expense of </p>
      <div className={styles.hoverCardList}>
      <FontAwesomeIcon
        icon={faArrowCircleRight} 
        className={styles.hoverCardIcon}
        />
          assessing seniors’ needs
      </div>
      <div className={styles.hoverCardList}>
      <FontAwesomeIcon
        icon={faArrowCircleRight} 
        className={styles.hoverCardIcon}
        />
        planning for the services they will use
      </div>
      <div className={styles.hoverCardList}>
      <FontAwesomeIcon
        icon={faArrowCircleRight} 
        className={styles.hoverCardIcon}
        />
        identifying and hiring the providers and
      </div>
      <div className={styles.hoverCardList}>
      <FontAwesomeIcon
        icon={faArrowCircleRight} 
        className={styles.hoverCardIcon}
        />
        monitoring service provider performance
        </div>
        </div>
      </HoverCardBlock>
            </Container>
      
      <Container style={{
        paddingTop: '2rem',
      }} />

      <Container className="big-y-margin">
        <CompleteCareTabs />
      </Container>

      <CommunicationIsEverything />

      <PredictableCostTabs />

      <Assessment />

      <FooterContact />
  </Layout>
)
}

export default Overview

import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import styles from "./CompleteCarePage.module.css"

const CommunicationIsEverything = () => {
  const data = useStaticQuery(graphql`
    {
      iMaciPhone: file(relativePath: { eq: "iMac-Pro-2018-Mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container
    className={styles.communicationContainer}
    fluid 
    >
 
        {/* <div style={{
                  width: '100%',
                  height: '80%',
                  backgroundColor: '#3B5272',
                  position: 'absolute'
              }}> </div> */}
          <Container 
            className={styles.communicationCard}
            fluid
          >
            <Row className="h-100 align-items-center">
              <Col sm={6} className="justify-content-center text-center d-flex">
                <Img
                  fluid={data.iMaciPhone.childImageSharp.fluid}
                  style={{
                    width: "600px",
                  }}
                  alt="iMac and iPhone Interfaces"
                />
              </Col>
              <Col sm={6}
                // style={{
                //     marginBottom: '2%'
                // }}
              >
                <h1
                  className={`${styles.uniqueCare} mb-4`}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Communication is Everything
                </h1>
                <p className={styles.carouselText}>Symphony uses our Care Plan App, a cloud based application to ensure that a senior’s trusted care team, relatives, and advisors have around the clock access to the latest conditions, medications, scheduled appointments, any tasks that need attention, and contact information for all on the individual’s care team.  This comprehensive communication helps reduce anxiety, confusion, and costly mistakes while giving loved ones peace of mind.</p>
              </Col>
            </Row>
          </Container>

    </Container>
  )
}

export default CommunicationIsEverything

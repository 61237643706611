import React from "react";
import styled from "styled-components"

const Icon = styled.svg `
  width: 45px;
  height: 44px;
  
  .cls-1{
    fill: #FFBB00;
  }
`;

const DetailedCareIcon = () => {
    return (

<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.64516 44">
  <title>detailed-care</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path className="cls-1" d="M7.0185,32.1178a4.8785,4.8785,0,1,0,4.87832,4.87868A4.88425,4.88425,0,0,0,7.0185,32.1178Zm0,8.33765a3.45915,3.45915,0,1,1,3.459-3.459A3.463,3.463,0,0,1,7.0185,40.45545Z"/>
        <path className="cls-1" d="M21.60535,0H0V44H32.64516V11.03988Zm.4158,2.42291,8.20117,8.2011H22.02115ZM1.41935,29.01168l.74261.547.40267-.23426a8.81223,8.81223,0,0,1,2.17857-.90058l.4505-.11923L5.7041,24.932H8.33282l.51047,3.37267.45043.11923a8.8227,8.8227,0,0,1,2.17864.90058l.4026.23426,2.74482-2.02265,1.85879,1.85872-2.023,2.74517.23455.40267a8.8031,8.8031,0,0,1,.901,2.17822l.11916.4505,3.37274.5104V38.3108l-3.37274.51047-.11916.45043a8.81993,8.81993,0,0,1-.901,2.17857l-.23455.403.53637.72735H1.41935Zm15.33578,13.569-.611-.82884a10.25652,10.25652,0,0,0,.68852-1.66476l3.66968-.55582V34.4613l-3.67-.55582a10.22536,10.22536,0,0,0-.68817-1.66434l2.20142-2.987-3.58508-3.58444-2.98668,2.20078a10.26694,10.26694,0,0,0-1.66469-.68817l-.55582-3.66967H4.48367l-.55582,3.66967a10.25437,10.25437,0,0,0-1.66469.68817l-.84381-.62175V1.41935H20.60179v10.624h10.624V42.58065Z"/>
        <rect className="cls-1" x="4.25806" y="3.54839" width="6.3871" height="1.41935"/>
        <rect className="cls-1" x="4.25806" y="7.09677" width="13.48387" height="1.41935"/>
        <rect className="cls-1" x="4.25806" y="10.62955" width="13.48387" height="1.41935"/>
        <rect className="cls-1" x="4.25806" y="14.16275" width="24.12903" height="1.41935"/>
        <rect className="cls-1" x="4.25806" y="17.69552" width="24.12903" height="1.41935"/>
        <rect className="cls-1" x="26.25806" y="21.29032" width="1.41935" height="1.41935"/>
        <rect className="cls-1" x="23.41559" y="21.29032" width="1.41935" height="1.41935"/>
        <rect className="cls-1" x="20.57305" y="21.29032" width="1.41935" height="1.41935"/>
      </g>
    </g>
  </g>
</Icon>
    )
}
export default DetailedCareIcon

import React from 'react'
import { Accordion, Button } from 'react-bootstrap'
import styles from './CompleteCarePage.module.css'

const PredictableCostFaq = () => (
    <Accordion>
        <Accordion.Toggle as={Button} variant="link" eventKey="0" className={styles.faqLink}>
            Will Symphony pay for all of my home care services?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
            <p>Our base fee covers the costs of Symphony’s care coordination services and communication tools only.  With our help, clients will contract directly for caretaking, home health, home maintenance and other services provided by our care partners or other providers.  The client is responsible for paying for these services directly.</p>
        </Accordion.Collapse>
        <Accordion.Toggle as={Button} variant="link" eventKey="1" className={styles.faqLink}>
            What happens if I’m dissatisfied with the home care providers I’ve chosen?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
            <p>Symphony will advocate for the changes necessary, such as identifying different individuals from the same organization or, if necessary, bringing in a new organization. These transitions can be awkward but not uncommon as trust and comfort are just as important as skills; Symphony is here to support seniors in order to work through the transitions.</p>
        </Accordion.Collapse>
        <Accordion.Toggle as={Button} variant="link" eventKey="2" className={styles.faqLink}>
            How can my family follow the Care Plan implementation and problems that arise?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
            <p>Symphony provides a personal Care Team contact for the client’s and family’s direct access. We also provide a direct link to our Care Plan App, a cloud-based communication application via CareTree (the App developer) to afford 24/7 access to provider progress notes, activities and events calendar, current medications and key records and documents.</p>
        </Accordion.Collapse>
        <Accordion.Toggle as={Button} variant="link" eventKey="3" className={styles.faqLink}>
        Suppose there’s a medical emergency &mdash; how does Symphony respond?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
            <p>Our clients and families should ALWAYS call 911 in the event of medical emergencies to ensure the most rapid medical response possible. Symphony provides the first responders with client and family contact information and alerts family members to the situation. Our Care Team visits the hospital or urgent care facility and updates all providers and family members on the steps taken and plans for returning home.</p>
        </Accordion.Collapse>
        <Accordion.Toggle as={Button} variant="link" eventKey="4" className={styles.faqLink}>
        My mother just passed away and no longer needs Symphony’s services &mdash; can we cancel your services?
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
            <p>Of course.  However, there may be a need for Symphony to assist with funeral arrangements, medical claims, bill paying and other items until the family gains closure on the death.  Symphony will continue its services as long as the family desires.</p>
        </Accordion.Collapse>
    </Accordion>
)

export default PredictableCostFaq
import React from "react"
import { Container, Row, Col, Tab, Nav } from "react-bootstrap"
import AnchorLink from "react-anchor-link-smooth-scroll"

import "@fortawesome/fontawesome-svg-core/styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleRight,
  faArrowCircleRight,
} from "@fortawesome/pro-light-svg-icons"

import styles from "./CompleteCarePage.module.css"

import PredictableCostFaq from "./PredictableCostFaq"

const PredictableCostTabs = () => (
  <>
    <Container className="mb-2">
      <Row>
        <Col>
          <h1
            className={`${styles.uniqueCare} mb-4`}
            style={{
              textAlign: "center",
            }}
          >
            Orchestrated Care at One Predictable Cost
          </h1>
          <div
            className="text-center"
            style={{
              width: "auto",
              height: "2px",
              borderBottom: "2px solid #7CB3FE",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Col>
      </Row>
    </Container>
    <Container className="my-2">
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="pricing-tab"
        className="mt-2"
      >
        <Row>
          <Col sm={4}>
            <Nav className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="pricing-tab" className="tabTitles">
                  <div className={styles.tabTitleContainer}>
                    <div className={styles.tabIconContainer}>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="arrow-icon arrowCircle"
                        aria-hidden="true"
                      />
                    </div>
                    <div>
                      <p style={{ marginBottom: 0, lineHeight: 1 }}>
                        <strong>Pricing</strong>
                      </p>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="responsibilities-tab" className="tabTitles">
                  <div className={styles.tabTitleContainer}>
                    <div className={styles.tabIconContainer}>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="arrow-icon"
                        aria-hidden="true"

                      />
                    </div>
                    <div>
                      <p style={{ marginBottom: 0, lineHeight: 1 }}>
                        <strong>Responsibilities</strong>
                      </p>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="faq-tab" className="tabTitles">
                  <div className={styles.tabTitleContainer}>
                    <div className={styles.tabIconContainer}>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="arrow-icon"
                        aria-hidden="true"

                      />
                    </div>
                    <div>
                      <p style={{ marginBottom: 0, lineHeight: 1 }}>
                        <strong>FAQ</strong>
                      </p>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="pricing-tab" className={styles.tabContent}>
                <p>
                  We believe that our clients benefit by knowing that our
                  services come at a predictable, fixed monthly rate. Rather
                  than charging hourly fees of $100-125, Symphony spreads the
                  costs of all of its services over an initial six-month period
                  at a fixed rate per month. Base rates vary according to client
                  needs and circumstances, but generally range between $500 and
                  $1,000 per month for our full-time care-coordination services
                  and Care Plan App. Some of our clients need additional, a la
                  carte, services for special circumstances, more frequent home
                  visits or other services, which are priced separately. Our
                  care partners bill the client directly for their services or
                  third-party insurance as appropriate. If, following the
                  initial assessment, a client does not wish to pursue care
                  coordination with Symphony, the client will simply pay a $500
                  fee for Symphony’s comprehensive assessment which the client
                  may keep. If you choose to continue with Symphony, the
                  assessment is included in the monthly fee.
                </p>
              </Tab.Pane>
              <Tab.Pane
                eventKey="responsibilities-tab"
                className={styles.tabContent}
              >
                <p>
                  Our predictable monthly fee includes Symphony’s Care Team’s
                  broad range of care coordination services as described in
                  detail{" "}
                  <AnchorLink href="#complete-care-approach" offset="200">
                    here
                  </AnchorLink>
                  . However, it is important to point out that for our support
                  services to blossom, our clients will be responsible for:
                </p>
                <BulletContainer 
                  bulletContent="Authorizing Symphony to obtain personal and medical
                  information, information from family members, medical
                  providers and other legal representatives;"
                />
                <BulletContainer 
                  bulletContent="Arranging introductions to key legal, financial, medical and
                  other client advisors;"
                />
                <BulletContainer 
                  bulletContent="Responding in a timely fashion to Care Team requests and
                  inquiries;"
                />
                <BulletContainer 
                  bulletContent="Advising our Care Team immediately of any changes in client
                  circumstances or condition; and"
                />
                <BulletContainer 
                  bulletContent="Providing timely notice of intention to continue or
                  discontinue services after the initial six-month period."
                />
              
                <p>
                  In addition, clients are responsible for identifying any
                  special services provided by Symphony that are not provided as
                  part of the base fee above. These might include arranging for:
                </p>

                <BulletContainer 
                  bulletContent="out-of-state or extended travel plans;"
                />

                <BulletContainer 
                  bulletContent="unique activities, lessons or outings to meet specific
                  client needs;"
                />
                <BulletContainer 
                  bulletContent="more frequent than the twice per month Care Team home
                  visits;"
                />
                <BulletContainer 
                  bulletContent="a new Needs Assessment and Support Plan every three months;"
                />
                <BulletContainer 
                  bulletContent="an evaluation of assisted living or other senior group residential settings; or"
                />
                <BulletContainer 
                  bulletContent="services to a second individual residing in the home, such
                  as the Client’s spouse or other residential companion."
                />
               
                <p>
                  Clients will be responsible for retaining the services of
                  other organizations and professionals needed to support their
                  lives aging at home. Although Symphony’s Care team will advise
                  in selecting and retaining these providers, clients are
                  responsible for choosing, retaining and meeting any financial
                  obligations for these services not covered by third party
                  insurance. These providers include:
                </p>
                <BulletContainer 
                  bulletContent="Medical professionals, such as physicians, nurse practitioners, physician assistants, nurses and related providers;"
                />
                <BulletContainer 
                  bulletContent="Medical providers, such as providing acute care, rehabilitation services, home health care, physical therapy, palliative care and hospice care;"
                />
                <BulletContainer
                  bulletContent="Non-medical caretakers;"
                />
                <BulletContainer
                  bulletContent="Home maintenance services, and"
                />
                <BulletContainer
                  bulletContent="Legal, tax and financial advisory services"
                />
              </Tab.Pane>
              <Tab.Pane eventKey="faq-tab" className={styles.tabContent}>
                <PredictableCostFaq />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  </>
)

const BulletContainer = props => (
  <div className={styles.bulletContainer}>
    <div>
      <FontAwesomeIcon
        icon={faArrowCircleRight}
        className={styles.listArrowIcon}
      />
    </div>
    <div>
      <p style={{ marginBottom: 0 }}>
        {props.bulletContent}
      </p>
    </div>
  </div>
)

export default PredictableCostTabs

import React from "react"
import { Container, Row, Col, CardDeck, Card } from "react-bootstrap"
import styles from "./CompleteCarePage.module.css"

const CommunicationPlatform = () => (
    <Container className="mb-4">
        <Row>
            <Col className="col-12">
            <CardDeck>
                <Card className={styles.homeCard}>

                    <Card.Body>
                        <Card.Title 
                            className={styles.homeCardTitle}>
                            Immediate Assessment
                        </Card.Title>
                        <hr />
                        <Card.Text className={styles.homeCardBody}>
                        We believe in the whole person and use a holistic approach when taking a comprehensive assessment of the senior’s health status, residential and routine needs, financial and legal planning, plus family and social needs to build the foundation for coordinated care.
                    </Card.Text>
                    </Card.Body>
                </Card>
                <Card className={styles.homeCard}>
                    <Card.Body>
                        <Card.Title
                            className={styles.homeCardTitle}
                        >
                            Top-Rated Providers
                        </Card.Title>
                        <hr />
                        <Card.Text className={styles.homeCardBody}>
                        Our team works closely with the top rated providers serving Arizona seniors. We simplify the process of hiring providers and arranging care by assuming the burden of identifying, evaluating and monitoring the broad range of services needed for seniors to remain at home.
                    </Card.Text>
                    </Card.Body>
                </Card>
                <Card className={styles.homeCard}>
                    <Card.Body>
                        <Card.Title
                            className={styles.homeCardTitle}
                        >
                            24/7 Communication
                        </Card.Title>
                        <hr />
                        <Card.Text className={styles.homeCardBody}>
                        Symphony’s Care Plan App allows seniors, their relatives, providers, and their Care Team around the clock access to the most current and coordinated information including any new tasks, concerns, emergency info, or ever changing details in a senior’s Care Plan.
                    </Card.Text>
                    </Card.Body>
                </Card>
                <Card className={styles.homeCard}>
                    <Card.Body>
                        <Card.Title
                            className={styles.homeCardTitle}
                        >
                            Predictable Cost
                        </Card.Title>
                        <hr />
                        <Card.Text className={styles.homeCardBody}>
                        Symphony’s Care Plan App, Care Team, and service coordination comes at a fixed monthly fee based on the level of care needed and within the first six months we are able to complete the initial comprehensive assessment and establish continuity with the best service providers.
                    </Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
            </Col>
        </Row>
    </Container>
)

export default CommunicationPlatform
import React from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';

import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/pro-light-svg-icons"

import styles from "./CompleteCarePage.module.css"
import AssessmentsIcon from '../../images/icons/AssessmentsIcon';
import DetailedCareIcon from '../../images/icons/DetailedCareIcon';
import DynamicCareIcon from '../../images/icons/DynamicCareIcon'
import CommunicationPlatformIcon from "../../images/icons/CommunicationPlatformIcon"
import MonitoringIcon from "../../images/icons/MonitoringIcon"
import SupportIcon from "../../images/icons/SupportIcon"

const CompleteCareTabs = () => (
    <>
    <Container className="mb-2" id="complete-care-approach">
        <Row>
            <Col>
            <h1 className={`${styles.uniqueCare} mb-4`} style={{
            textAlign: 'center'
        }}>
            Our <span style={{fontStyle: 'italic', fontWeight: 'normal'}}>Unique</span> Complete Care Approach
        </h1>
        <div className="text-center" style={{
          width: 'auto',
          height: '2px',
          borderBottom: '2px solid #7CB3FE',
          marginLeft: 'auto',
          marginRight: 'auto'
        }} />
            </Col>
        </Row>
    </Container>
<Container className="my-2">
    <Tab.Container id="left-tabs-example" defaultActiveKey="assessment-tab" className="mt-2">
  <Row>
    <Col sm={4}>
      <Nav className="flex-column">
        <Nav.Item>
          <Nav.Link eventKey="assessment-tab" className="tabTitles">
              <div className={styles.tabTitleContainer}>
                <div className={styles.tabIconContainer}><AssessmentsIcon /></div>
                <div className={styles.tabIconContainer}>
                    <FontAwesomeIcon 
                        icon={faAngleRight}
                        className="arrow-icon"
                        aria-hidden="true" 
                        
                    />
                </div>
               <div><p style={{marginBottom: 0, lineHeight: 1}}><strong>Assessments</strong><br/><span className={styles.tabTitleSub}>A thorough look at the details.</span></p></div>
              </div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link eventKey="detailed-care-tab" className="tabTitles">
              <div className={styles.tabTitleContainer}>
                <div className={styles.tabIconContainer}><DetailedCareIcon /></div>
                <div className={styles.tabIconContainer}>
                    <FontAwesomeIcon 
                        icon={faAngleRight}
                        className="arrow-icon"
                        aria-hidden="true" 
                        
                    />
                </div>
               <div><p style={{marginBottom: 0, lineHeight: 1}}><strong>Detailed Care Plan</strong><br/><span className={styles.tabTitleSub}>Unique to each client’s needs.</span></p></div>
              </div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link eventKey="dynamic-care-tab" className="tabTitles">
              <div className={styles.tabTitleContainer}>
                <div className={styles.tabIconContainer}><DynamicCareIcon /></div>
                <div className={styles.tabIconContainer}>
                    <FontAwesomeIcon 
                        icon={faAngleRight}
                        className="arrow-icon"
                        aria-hidden="true" 
                        
                    />
                </div>
               <div><p style={{marginBottom: 0, lineHeight: 1}}><strong>Dynamic Care Team</strong><br/><span className={styles.tabTitleSub}>A support team for all areas of life.</span></p></div>
              </div>
          </Nav.Link>
          <Nav.Link eventKey="communication-tab" className="tabTitles">
              <div className={styles.tabTitleContainer}>
                <div className={styles.tabIconContainer}>
                  <CommunicationPlatformIcon />
                </div>
                <div className={styles.tabIconContainer}>
                    <FontAwesomeIcon 
                        icon={faAngleRight}
                        className="arrow-icon"
                        aria-hidden="true" 
                        
                    />
                </div>
               <div><p style={{marginBottom: 0, lineHeight: 1}}><strong>Care Plan App</strong><br/><span className={styles.tabTitleSub}>Cloud based technology to keep everyone informed in real time</span></p></div>
              </div>
          </Nav.Link>
          <Nav.Link eventKey="monitoring-tab" className="tabTitles">
              <div className={styles.tabTitleContainer}>
                <div className={styles.tabIconContainer}>
                  <MonitoringIcon />
                </div>
                <div className={styles.tabIconContainer}>
                    <FontAwesomeIcon 
                        icon={faAngleRight}
                        className="arrow-icon"
                        aria-hidden="true" 
                        
                    />
                </div>
               <div><p style={{marginBottom: 0, lineHeight: 1}}><strong>Monitoring</strong><br/><span className={styles.tabTitleSub}>Making sure services and needs are on track </span></p></div>
              </div>
          </Nav.Link>
          <Nav.Link eventKey="support-tab" className="tabTitles">
              <div className={styles.tabTitleContainer}>
                <div className={styles.tabIconContainer}>
                  <SupportIcon />
                </div>
                <div className={styles.tabIconContainer}>
                    <FontAwesomeIcon 
                        icon={faAngleRight}
                        className="arrow-icon"
                        aria-hidden="true" 
                        
                    />
                </div>
               <div><p style={{marginBottom: 0, lineHeight: 1}}><strong>24/7 Support</strong><br/><span className={styles.tabTitleSub}>Around the clock support with your care team and emergency contacts</span></p></div>
              </div>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={8}>
      <Tab.Content>
        <Tab.Pane eventKey="assessment-tab" className={styles.tabContent}>
            <p>We are committed to helping the whole person and believe in looking at all the details during our comprehensive assessment. Symphony comes to the home to assess not only the health and medical needs, but also the details in the residence, financial and life-care planning, and what family and social needs are crucial to maintain a healthy sustainable independence at home. This important first step is the foundation for building a custom care plan unique to each client’s needs.</p>
            <p><strong>Health Profile:</strong> Symphony provides a thorough assessment of your health including an  assessment of  limitations in Activities of Daily Living (ADL/IADL), chronic conditions and treatment, cognitive tests (SLUMS), mood (PHQ-9) and activities.</p>
            <p><strong>Medical Plan Profile:</strong> We review current Medicare and supplemental insurance plans, current medications and drug plan, provider plans for ongoing care, and assess eligibility for other plans and coverage.</p>
            <p><strong>Residential Profile:</strong> Symphony evaluates utilities, home and yard maintenance conditions, equipment functionality, fall risk factors and mobility conditions, accessibility to grocery shopping and meal prep; pet care; transportation teams; home nursing, palliative, dementia, and hospice care.</p>
            <p><strong>Financial Profile: </strong>We review the integrity of routine financial & legal needs, such as life-care planning, power of attorney documents, medical claim reconciliation, automated on-line bill-paying, credit card monitoring, budgeting and prevention of fraud, and coordination with professional advisors to consolidate important documents into the Care Plan App.</p>
            <p><strong>Family &amp; Social Profile:</strong> Symphony assists in organizing and documenting personal and family information, family decision-making roles, Powers of Attorney (POAs, MPOAs), Advanced Directives (ADs), social needs and activity schedule. Our Care Plan App keeps everyone up-to-date with the built in calendar feature.</p>
        </Tab.Pane>
        <Tab.Pane eventKey="detailed-care-tab" className={styles.tabContent}>
        <p>After completing the assessment, our Symphony Care Team builds a comprehensive Care Plan using best practises in senior care and life-care planning, that caters to the unique services needed by each individual. Unlike most senior care companies, our plan has four categories to support the whole person:</p>
        <p><strong>Health &amp; Medical Services:</strong> that support your optimal well being are coordinated with the best-suited physicians and care professionals; monitoring of medication use; advocating for cost effective patient care when virtual visits or in home care is most efficient and advantageous; encouraging exercise and healthy habits, and more.</p>
        <p><strong>Residential &amp; Routine Services:</strong> that clarify roles for caretakers and homemakers; coordinate home and garden maintenance contractors, grocery shopping and meal prep; pet care; transportation teams; home nursing, palliative, dementia, and hospice care.</p>
        <p><strong>Financial &amp; Legal Services:</strong> that simplify routine financial management, such as automated on-line bill-paying, credit card monitoring, budgeting and funding for routine expenses; assuring accurate benefit payments; preventing fraud, theft by providers or others; and ensure that essential legal documentation for Life-Care Planning is in place.</p>
        <p><strong>Family &amp; Social Communication Services</strong> that are adapted to your preferences. Symphony’s Care Team uses the Care Plan App to organize all the details to keep everyone informed. Services also include coaching families; mediating conflicting views; services needed to maintain social connections, activities, and spiritual support.</p>
        </Tab.Pane>
        <Tab.Pane eventKey="dynamic-care-tab" className={styles.tabContent}>
        <p>Symphony’s Care Team is dedicated to orchestrating the highest quality of life for seniors living at home. Our team of experienced nursing, counseling and administrative professionals handle the range of services that will be needed. We coordinate and collaborate with additional care partners to provide holistic support for seniors living at home. Symphony’s dynamic team has substantial experience in:</p>
        <p><strong>Health &amp; Medical Services: </strong>Our Symphony Care Team ensures that your Medicare and Supplemental health plans are optimized for your overall health needs, medications and finances. Through our experience and that of our care partners, we have unique access to key specialists and a wide range of professionals to get you the care you deserve.  Many of our care partners specialize in bringing their services to the home, including urgent care, diagnostic services, physical therapy and exercise like yoga and pilates.  We deeply believe in compassionate patient advocacy.</p>
        <p><strong>Residential &amp; Routine Services:</strong> We understand the many layers of maintaining quality of life at home and it is so much more than meets the eye.  Our Symphony Care Team coordinates not only caretaking, but all the other aspects of home life like transportation, home & yard maintenance, cleaning, social activities, pet exercise, meal planning, grocery shopping and connection with friends and neighbors.  Trust us that seniors’  needs are being met to live safely and comfortably at home.  You and your family can have peace of mind knowing that we are watching out for every detail.</p>
        <p><strong>Financial &amp; Legal Services:</strong> We are not a bank or wealth management professionals, but we excel at collaborating with your trusted professionals and family to simplify routine finances, like bill-paying and budgeting, reviewing checking accounts and credit cards to prevent theft and fraud, reconciling medical claims to ensure correct charges and reimbursements, and helping plan for future needs with our Life-Care Planning partners.</p>
        <p><strong>Communication Tools:</strong> Symphony’s Care Plan App keeps seniors closely connected with their family, providers and social network. This platform lets our team organize, manage ongoing changes, and communicate personal and medical information and documents needed by the family and providers for seniors’ services. Our Care Team maintains close and continual contact with the senior, family, and providers ensuring that our team is ready to address changes in circumstances or medicating conflicts.</p>
        <p><strong>Coordinating Care Partners:</strong> We know every life has many aspects, so we partner with a vast array of service providers to enhance your life and provide holistic care to meet your unique needs. Symphony is also a corporate partner with the Aging Life Care Association, a group which provides helpful support and training for our Care Team professionals serving seniors.</p>
        </Tab.Pane>
        <Tab.Pane eventKey="communication-tab" className={styles.tabContent}>
        <p>Symphony For Seniors believes that quality of life is in the details...  And each individual has a unique set of details to manage. This is why Symphony has found great success for our clients using our care-coordination service and Care Plan App, a cloud-based communication platform with 24/7 access. Seniors, family and advisors have access to one central location for the most recent vitals, medical records, medications, legal documents, residential information, contact details, notes, calendar events, and task lists. Our Care Plan App provides easy access to all contact information for healthcare providers, Symphony’s Care Team, and our care partners to monitor ever-changing needs of an individual, quickly get up to speed on their most current condition reducing delay of care.</p>
        <p>At the client’s option and based on availability, Symphony provides an electronic tablet personalized for each senior’s unique needs, services and communication preferences.</p>

        </Tab.Pane>
        <Tab.Pane eventKey="monitoring-tab" className={styles.tabContent}>
          <p>In a world full of details, it is vital to have someone watching out for your well-being.  Our Symphony Care Team provides full-time monitoring over all of the services necessary to support seniors at home. The Symphony Care Team assigned to each client will routinely meet with the senior and monitor any progress, change, and problems that arise while updating family and care providers on client activities, visits, changes in condition and medications. We also monitor the services and billing by our care partners to ensure the level of care is correct and timely.  As changes occur in health, medical, residential, legal or socially, our Care Team identifies the best solutions and keeps all parties informed using the Care Plan App so the whole team is up-to-date and on task.</p>
        </Tab.Pane>
        <Tab.Pane eventKey="support-tab" className={styles.tabContent}>
          <p>Peace of mind is knowing that you have a support team day and night.  Symphony For Seniors offers you around the clock support from your Care Team and continuous access to the Symphony Care Plan App. Login to your Care Plan 24/7 for details on medications, appointments, tasks, important contacts and the latest updates. Should an emergency arise, the Care Plan App allows instant access to vital information to reduce delay of care and complications that arise from lack of or improper information in emergency situations.</p>
          <p>Our Symphony Care Team works hard to ensure that you have what you need to thrive and life your best quality of life at home. We are here to help.</p>

        </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container>
</Container>

</>
)

export default CompleteCareTabs;
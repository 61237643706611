import React from "react";
import styled from "styled-components"

const Icon = styled.svg `
  width: 45px;
  height: 44px;
  
  .cls1, .cls2 {
    fill: #294160;
  }
  .cls2 {
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 0.27786px;
  }
`;

const AssessmentsIcon = () => {
    return (

<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.23185 44.01393">
  <title>tabs-assessments-icon</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <g>
            <path class="cls1" d="M34.95531,20.72784,24.94393,29.28962q-.01119.11343-.02225.2268h2.26216V39.73327H42.72671V29.51642h2.50514Zm6.41988,17.65447H31.23849v-.00366H28.53536V29.51642H41.37519ZM28.33737,28.16546l6.61794-5.65985,6.61787,5.65985Z"/>
            <rect class="cls1" x="37.32415" y="32.94999" width="1.35155" height="1.35098"/>
            <rect class="cls1" x="34.61739" y="32.94999" width="1.35155" height="1.35098"/>
            <rect class="cls1" x="31.91097" y="32.94999" width="1.35155" height="1.35098"/>
          </g>
          <g>
            <path class="cls1" d="M34.66854,39.21558a2.02529,2.02529,0,0,1-1.89121,1.31392H9.80087A2.02908,2.02908,0,0,1,7.77355,38.503V10.13241A2.02911,2.02911,0,0,1,9.80087,8.10587h3.37886v.67552a2.02913,2.02913,0,0,0,2.02732,2.02646h12.164A2.02913,2.02913,0,0,0,29.3984,8.78139V8.10587H30.104a3.69775,3.69775,0,0,1,1.06139-1.35095h-1.767V6.07941a2.02913,2.02913,0,0,0-2.02732-2.02647H25.97131a4.7311,4.7311,0,0,0-9.36449,0H15.20705a2.02913,2.02913,0,0,0-2.02732,2.02647v.67551H9.80087A3.38218,3.38218,0,0,0,6.422,10.13241V38.503a3.38217,3.38217,0,0,0,3.37892,3.37742H32.77733a3.37579,3.37579,0,0,0,3.26018-2.53731c-.08862.005-.16658.02564-.25845.02564A4.29519,4.29519,0,0,1,34.66854,39.21558ZM14.53132,6.07941a.676.676,0,0,1,.67573-.67545h2.70313V4.72845a3.37889,3.37889,0,0,1,6.75777,0V5.404h2.70313a.6761.6761,0,0,1,.6758.67545v2.702a.67616.67616,0,0,1-.6758.67551h-12.164a.6761.6761,0,0,1-.67573-.67551Z"/>
            <path class="cls1" d="M9.12508,20.26477h8.10933v-8.1059H9.12508Zm1.35156-6.75492h5.40622v5.40394H10.47664Z"/>
            <path class="cls1" d="M9.12508,39.17853h8.10933v-8.1059H9.12508Zm1.35156-6.75491h5.40622v5.40393H10.47664Z"/>
            <path class="cls1" d="M9.12508,29.72165h8.10933v-8.1059H9.12508Zm1.35156-6.75491h5.40622v5.40393H10.47664Z"/>
            <polygon class="cls1" points="20.556 16.542 19.738 15.732 18.786 16.692 20.671 18.557 23.843 14.608 22.79 13.762 20.556 16.542"/>
            <polygon class="cls1" points="20.556 25.999 19.738 25.188 18.786 26.149 20.671 28.014 23.843 24.065 22.79 23.219 20.556 25.999"/>
            <polygon class="cls1" points="20.556 35.456 19.738 34.645 18.786 35.606 20.671 37.471 23.843 33.522 22.79 32.676 20.556 35.456"/>
            <rect class="cls1" x="20.6133" y="6.75494" width="1.35155" height="1.35098"/>
            <rect class="cls1" x="23.31641" y="6.75494" width="1.35155" height="1.35098"/>
            <rect class="cls1" x="17.91019" y="6.75494" width="1.35155" height="1.35098"/>
          </g>
        </g>
        <path class="cls1" d="M36.29718,11.667V9.57715a1.08865,1.08865,0,0,1,.6417.85522l1.3529-.17642a2.23624,2.23624,0,0,0-.6417-1.28017,2.29509,2.29509,0,0,0-1.3529-.57993V7.8667h-.77537v.52915a2.23021,2.23021,0,0,0-1.47311.68688,2.0822,2.0822,0,0,0-.55346,1.47253,2.271,2.271,0,0,0,.48924,1.47259,2.94813,2.94813,0,0,0,1.53733.906v2.2396a1.46263,1.46263,0,0,1-.524-.449,1.77648,1.77648,0,0,1-.32083-.73761l-1.39573.14965a2.83724,2.83724,0,0,0,.738,1.63027,2.47522,2.47522,0,0,0,1.50258.68952v.97284h.77537v-.99955a2.57384,2.57384,0,0,0,1.62836-.81247,2.37429,2.37429,0,0,0,.58546-1.63026,2.12951,2.12951,0,0,0-.46521-1.41911A3.45286,3.45286,0,0,0,36.29718,11.667Zm-.77537-.25655a1.18648,1.18648,0,0,1-.51861-.39285.89655.89655,0,0,1-.17121-.52651.93957.93957,0,0,1,.18721-.56658,1.00364,1.00364,0,0,1,.50261-.36345Zm1.42779,3.4663a1.089,1.089,0,0,1-.65242.37147V13.1637a1.3508,1.3508,0,0,1,.69255.39818.947.947,0,0,1,.21124.61736A1.04546,1.04546,0,0,1,36.9496,14.87679Z"/>
        <path class="cls2" d="M35.96892,4.181a8.14363,8.14363,0,1,0,8.147,8.1435A8.15453,8.15453,0,0,0,35.96892,4.181Zm0,15.29054a7.14692,7.14692,0,1,1,7.14987-7.147A7.15651,7.15651,0,0,1,35.96892,19.47151Z"/>
        <path class="cls2" d="M13.94366,35.264,11.9219,31.86637l-2.87744,1.7109V30.23056H5.08981v3.34671l-2.87747-1.7109L.19047,35.264l3.00876,1.78883L.19047,38.84161l2.02187,3.39733,2.87747-1.71088V43.875H9.04446V40.52806l2.87744,1.71088,2.02176-3.39733L10.935,37.05278Z"/>
        <path class="cls1" d="M36.43645,20.15324a.55567.55567,0,0,0-.78589,0,.54577.54577,0,0,0-.16278.39295c.02984.355-.0871.70245-.05872,1.05737a.59363.59363,0,0,0,.16272.39294.55574.55574,0,0,0,.78595,0,.54679.54679,0,0,0,.16272-.39294c-.02832-.35492.08862-.70239.05878-1.05737A.59518.59518,0,0,0,36.43645,20.15324Z"/>
      </g>
    </g>
  </g>
</Icon>

    )
};

export default AssessmentsIcon
